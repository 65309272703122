declare const window: any

import React, { useEffect } from 'react'
import Script from 'next/script'
import { assetUrl } from '../helpers/common'
import { init as initSentry } from '@sentry/node'
import '@microfrontends/app-shell-v2/dist/index.css'
import '@microfrontends/react-components/public/index.css'

import '../global-styles/index.scss'
import {
    onLCP,
    onINP,
    LCPMetricWithAttribution,
    INPMetricWithAttribution,
    onCLS,
    CLSReportCallbackWithAttribution,
    CLSMetricWithAttribution
} from 'web-vitals/attribution'

/**
 * Script tracking gtag web-vitals - Start
 */
type MetricDebugInfo = {
    debug_target: string
    debug_event?: string
    event_time?: number
    event_render_time?: number
    event_load_time?: number
    event_end_time?: number
    load_state?: 'loading' | 'dom-interactive' | 'dom-content-loaded' | 'complete'
    time_to_first_byte?: number
    url?: string
}

type MetricData = {
    event_label: string
    value: number
    metric_value: number
    metric_delta: number
    metric_rating: 'good' | 'needs-improvement' | 'poor'
}

/**
 * We set the alias for `@sentry/node` to `@sentry/browser` in `next.config.js` so that
 * if case of browsers, the correct sdk would be used.
 *
 * WHY: It's because usually sentry would be used for both cases. So setting this using alias would
 * be the fastest approach
 */
const SENTRY_DSN = typeof window === 'object' ? process.env.SENTRY_CLIENT_DSN : process.env.SENTRY_SERVER_DSN
const SENTRY_ENABLED = process.env.NODE_ENV === 'production' || process.browser

const Container = (props) => {
    const { Component, pageProps } = props

    const getSelector = (node: any, maxLen = 100): string => {
        let sel = ''
        try {
            while (node && node.nodeType !== 9) {
                const part = node.id
                    ? '#' + node.id
                    : node.nodeName.toLowerCase() +
                      (node.className && node.className.length
                          ? '.' + Array.from(node.classList.values()).join('.')
                          : '')
                if (sel.length + part.length > maxLen - 1) return sel || part
                sel = sel ? part + '>' + sel : part
                if (node.id) break
                node = node.parentNode
            }
        } catch (err) {}
        return sel
    }

    const sendToAnalytics = (data: MetricData, debugInfo: MetricDebugInfo | undefined, name: string): void => {
        const w = window.innerWidth

        let eventData = {
            ...data,
            event_category: 'Web Vitals',
            non_interaction: true,
            page_path: location.pathname,
            page_width: w
        }

        if (!debugInfo) {
            debugInfo = {
                debug_target: '(not set)'
            }
        }

        eventData = {
            ...eventData,
            ...debugInfo
        }

        if (process.env.NODE_ENV === 'production') {
            // @ts-ignore
            gtag('event', name, eventData)
            console.log('gtag prod', name, eventData)
        } else {
            console.log('gtag dev', name, eventData)
        }
    }

    // @ts-ignore
    const debugLcpInfo: LCPReportCallbackWithAttribution = (metric: LCPMetricWithAttribution): void => {
        const { name, id, delta, value, entries, rating, attribution } = metric
        // console.log(`metric ${name}`, metric);

        let debugInfo: MetricDebugInfo | undefined = undefined
        if (!!entries && entries.length) {
            const lastEntry = entries[entries.length - 1]

            debugInfo = {
                debug_target: getSelector(lastEntry.element),
                event_time: lastEntry.startTime,
                event_render_time: lastEntry.renderTime,
                event_load_time: lastEntry.loadTime,
                time_to_first_byte: attribution.timeToFirstByte,
                url: attribution.url
            }
        }

        const data: MetricData = {
            value: Math.round(value * 1000),
            event_label: id,
            metric_value: value * 1000,
            metric_delta: delta,
            metric_rating: rating
        }

        sendToAnalytics(data, debugInfo, name)
    }

    // @ts-ignore
    const debugInpInfo: INPReportCallbackWithAttribution = (metric: INPMetricWithAttribution): void => {
        const { name, id, delta, value, rating, attribution } = metric
        // console.log(`metric ${name}`, metric);

        const { eventTarget, eventType, eventTime, eventEntry, loadState } = attribution

        const debugInfo: MetricDebugInfo = {
            debug_target: !!eventTarget ? eventTarget : '(not set)',
            debug_event: eventType,
            event_time: eventTime,
            event_end_time: eventEntry?.processingEnd,
            event_load_time: eventEntry?.duration,
            load_state: loadState
        }

        const data: MetricData = {
            value: Math.round(value * 1000),
            event_label: id,
            metric_value: value * 1000,
            metric_delta: delta,
            metric_rating: rating
        }

        sendToAnalytics(data, debugInfo, name)
    }

    // @ts-ignore
    const debugClsInfo: CLSReportCallbackWithAttribution = (metric: CLSMetricWithAttribution): void => {
        const { name, id, delta, value, rating, attribution } = metric
        // console.log(`metric ${name}`, metric);

        const { largestShiftTarget, largestShiftTime, loadState } = attribution

        const debugInfo: MetricDebugInfo = {
            debug_target: !!largestShiftTarget ? largestShiftTarget : '(not set)',
            event_time: largestShiftTime,
            load_state: loadState
        }

        const data: MetricData = {
            value: Math.round(value * 1000),
            event_label: id,
            metric_value: value * 1000,
            metric_delta: delta,
            metric_rating: rating
        }

        sendToAnalytics(data, debugInfo, name)
    }

    useEffect(() => {
        initSentry({
            dsn: SENTRY_DSN,
            enabled: SENTRY_ENABLED
        })

        onLCP(debugLcpInfo)
        onINP(debugInpInfo)
        onCLS(debugClsInfo)
    }, [])
    /**
     * Script tracking gtag web-vitals - End
     */

    return (
        <>
            <Script src={`${assetUrl('/js/chrome-fix.js')}`} />
            <Component {...pageProps} />
        </>
    )
}

export default Container
