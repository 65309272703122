import dayjs from 'dayjs'
import { CHARACTER_NOT_EXCEPTED_IN_URL_REGEX } from './regex'

export function assetUrl(path) {
    return process.env.APP_ASSET_URL + path
}

export function debounce<T extends Function>(cb: T, wait = 20) {
    let h: any = 0
    const callable = (...args: any[]) => {
        clearTimeout(h)
        h = setTimeout(() => cb(...args), wait)
    }
    return <T>(<any>callable)
}

export const throttle = (func, limit) => {
    let lastFunc
    let lastRan
    return function () {
        // @ts-ignore
        const context = this
        const args = arguments
        if (!lastRan) {
            func.apply(context, args)
            lastRan = Date.now()
        } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args)
                    lastRan = Date.now()
                }
            }, limit - (Date.now() - lastRan))
        }
    }
}

export function getCurrentTimestamp() {
    return Math.floor(Date.now() / 1000)
}

export function stripTags(htmlText) {
    if (process.browser) {
        const div = document.createElement('div')
        div.innerHTML = htmlText
        return div.textContent || div.innerText || ''
    }

    return htmlText
}

export function setCookie(name: string, value: string, days: number = 0, domain: string = '') {
    let expires
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    } else expires = ''

    if (domain !== '') {
        document.cookie = name + '=' + value + expires + '; domain=' + domain + ';path=/'
    } else {
        document.cookie = name + '=' + value + expires + '; path=/'
    }
}

export function getDateFromTS(ts) {
    return new Date(ts * 1000)
}

export function getDiffTextFromNow(date: Date, language = 'vi') {
    const months = getDiffMonthFromNow(date)

    let timeNumber = 1
    let timeText = language === 'en' ? 'day' : 'ngày'

    if (months > 0) {
        timeNumber = months
        timeText = language === 'en' ? (timeNumber > 1 ? 'months' : 'month') : 'tháng'
    } else {
        const days = getDiffDayFromNow(date)

        timeNumber = days
        timeText = language === 'en' ? (timeNumber > 1 ? 'days' : 'day') : 'ngày'
    }

    return `${timeNumber} ${timeText}`
}

export function getDiffMonthFromNow(date: Date) {
    let months
    const d1 = date
    const d2 = new Date()

    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth() + 1
    months += d2.getMonth()
    return months <= 0 ? 0 : months
}

export function getDiffDayFromNow(date: Date) {
    return dayjs(date).diff(dayjs(), 'day')
}

export function getPathFromUrl(url: string) {
    return url.split(/[?#]/)[0]
}

export function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export function addPageParamToUrl(url: string, currentUrlSearchParams: string, page: number) {
    let returnUrl = url
    let pageNumber = page > 0 ? page : 1

    if (currentUrlSearchParams.length > 0) {
        const params = currentUrlSearchParams.split('&')
        const newParams = []
        // Skip param that name match 'page'
        for (let i = 0; i < params.length; i++) {
            const paramInfo = params[i].split('=')
            if (paramInfo[0] === 'page') {
                continue
            }
            newParams.push(params[i])
        }
        // Push back param with name 'page' back to params array
        if (pageNumber > 1) {
            newParams.push(`page=${pageNumber}`)
        }
        const returnUrlWithoutParam = returnUrl.split('?')
        // return url with or without params
        if (newParams.length > 0) {
            returnUrl = `${returnUrlWithoutParam[0]}?${newParams.join('&')}`
        } else {
            returnUrl = returnUrlWithoutParam[0]
        }
    } else {
        if (pageNumber > 1) {
            returnUrl = `${url}?page=${pageNumber}`
        } else {
            returnUrl = url
        }
    }

    return returnUrl
}

export function removedSpecialCharacter(url: string) {
    return url.replace(CHARACTER_NOT_EXCEPTED_IN_URL_REGEX, '')
}

export function isKeyWordFromExternal() {
    const currentPath = typeof window !== 'undefined' ? window.location.pathname : ''
    const keywordRegex = new RegExp(/-kv|-kw/)
    return keywordRegex.test(currentPath)
}
